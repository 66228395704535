import Button, { ButtonProps } from './buttons/Button'

type LinkProps = ButtonProps & {}
const Link = (props: LinkProps) => {
  return (
    <Button appearance="text" color="primary" fontWeight={400} {...props} />
  )
}

export default Link
export { Link }
